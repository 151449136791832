import { environment } from '../environments/environment';
import { StorageConstants } from './constants/storage-constants';
import { ServiceLocator } from './locator/locator.service';
import { SessionStorageService } from './session-storage.service';
import { ToastService } from './toast/toast.service';

const fullObjCopy = (obj) => JSON.parse(JSON.stringify(obj));

export default async (
  method,
  url,
  data = null,
  options = {},
  type = 'json'
) => {
  const injector = ServiceLocator.injector;
  const [toastService, sessionStorageService] = await Promise.all([
    injector?.get(ToastService),
    injector?.get(SessionStorageService),
  ]);
  options = options || {};
  type = type || 'json';
  const endpoint = `${environment.apiUrl}${url}`;
  const token = await sessionStorageService?.get(
    StorageConstants.RM_ECOMMERCE_TOKEN
  );

  if (token) {
    // FIXME oauth2
    const auth = 'Authorization';
    options[auth] = 'Bearer ' + token;
  }
  try {
    const res = await window.fetch(endpoint, {
      method,
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        'Content-Type': 'application/json',
        'X-Custom-Origin': 'RM_POLICARBONATOS',
        ...options,
      },
    });
    if (res.status > 302) {
      let errRes;
      try {
        errRes = await res.json();
      } catch (err) {
        try {
          errRes = await res.text();
        } catch (err) {
          throw res;
        }
      }
      if (errRes.error === 'invalid_token') {
        toastService.error(
          'Sessão Expirada no servidor. Refaça login do usuário conectado!'
        );
        sessionStorageService.clear();
        sessionStorageService.set(
          StorageConstants.RM_ECOMMERCE_PAGE,
          'customer'
        );
        window.location.reload();
      }
      throw errRes;
    }
    let content;
    if (type === 'json') {
      const json = await res.json();
      content = json;
    } else {
      if (type === 'blob') {
        const json = await res.blob();
        return json;
      } else {
        const text = await res.text();
        content = text;
      }
    }
    return fullObjCopy(content);
  } catch (e) {
    console.error(e);
    throw e;
  }
};
