import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService } from 'src/services/toast/toast.service';
import File from '../../../util/file';

export interface ImageGalleryImage {
  id?: number;
  base64?: string;
  src?: string;
  url?: string;
  name?: string;
  description?: string;
}

interface ImageGalleryOrderIndexChange {
  fromIndex: number;
  toIndex: number;
}
interface ImageGalleryChange {
  oldImage: ImageGalleryImage;
  newImage: ImageGalleryImage;
}

let dragging;

@Component({
  selector: 'app-image-gallery-selection',
  template: `
    <div class="app-image-gallery-selection">
      <div
        *ngFor="let image of images; index as i"
        [ngStyle]="{ backgroundImage: getBackgroundImage(image) }"
        class="app-image-gallery-selection-image pointer"
        (click)="showing = image"
        (drag)="drag($event, image)"
        (drop)="drop($event, image)"
        (dragenter)="dragEnter($event)"
        (dragover)="dragOver($event)"
        (dragleave)="dragLeave($event)"
        draggable="true"
      ></div>

      <button
        class="app-image-gallery-selection-add"
        *ngIf="images.length < insertLimit"
        (click)="addImage()"
      >
        <img src="assets/img/add.svg" />
      </button>
    </div>

    <app-modal
      innerClass="image-gallery"
      *ngIf="showing"
      (closed)="showing = null"
    >
      <div class="show-img-wrp">
        <img
          [src]="showing.src"
          [alt]="showing.description || 'Imagem'"
          class="show-image"
        />
      </div>
      <div class="modal-actions">
        <button (click)="update()">Alterar</button>
        <button (click)="destroy()" class="danger">Deletar</button>
      </div>
    </app-modal>
  `,
})
export class ImageGalleryComponent {
  @Input()
  public images: Array<ImageGalleryImage> = [];

  @Input()
  public insertLimit = 6;

  @Output()
  public added: EventEmitter<ImageGalleryImage> =
    new EventEmitter<ImageGalleryImage>();

  @Output()
  public deleted: EventEmitter<ImageGalleryImage> =
    new EventEmitter<ImageGalleryImage>();

  @Output()
  public updated: EventEmitter<ImageGalleryChange> =
    new EventEmitter<ImageGalleryChange>();

  @Output()
  public orderChanged: EventEmitter<ImageGalleryOrderIndexChange> =
    new EventEmitter<ImageGalleryOrderIndexChange>();

  public showing: ImageGalleryImage;

  public windowHeight: number;

  constructor(private toastService: ToastService) {
    this.windowHeight = window.innerHeight;
    document.addEventListener('mouseup', () => (dragging = null));
  }

  async addImage() {
    try {
      const images: any = await File.openFile(true);
      for (const imgBase64 of images) {
        if (this.images.length >= this.insertLimit) {
          continue;
        }
        const image: ImageGalleryImage = { src: `${imgBase64}` };
        this.images.push(image);
        this.added.emit(image);
      }
    } catch (error) {
      this.toastService.error(error);
    }
  }

  async update() {
    try {
      const imgBase64: any = await File.openFile(false);
      const image: ImageGalleryImage = { src: `${imgBase64}` };
      this.images[this.images.indexOf(this.showing)] = image;
      this.added.emit(image);
      this.updated.emit({ newImage: image, oldImage: this.showing });
      this.showing = null;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  destroy() {
    this.deleted.emit(this.showing);
    this.images.splice(this.images.indexOf(this.showing), 1);
    this.showing = null;
  }

  getBackgroundImage(image: ImageGalleryImage) {
    return `url('${image.src}')`;
  }

  dragOver(event) {
    event.preventDefault();
    event.toElement.style.border = '5px solid yellow';
  }

  dragLeave(event) {
    event.preventDefault();
    event.toElement.style.border = '5px solid transparent';
  }

  drag(event, image: ImageGalleryImage) {
    dragging = this.images.indexOf(image);
  }

  dragEnter(event) {
    event.preventDefault();
  }

  drop(event, image: ImageGalleryImage) {
    event.preventDefault();
    event.target.style.border = '5px solid transparent';
    if (!`${dragging}`) {
      return;
    }
    const toIndex = this.images.indexOf(image);
    const fromIndex = dragging;
    const cp = this.images[toIndex];
    this.images[toIndex] = this.images[fromIndex];
    this.images[fromIndex] = cp;
  }
}
