import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePT from '@angular/common/locales/pt';
import {
  DEFAULT_CURRENCY_CODE,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgGridModule } from 'ag-grid-angular';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { ServiceLocator } from 'src/services/locator/locator.service';
import { OnlyNumberDirective } from 'src/util/only-number.directive';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';
import { AddressComponent } from './components/address/address.component';
import { BlockLoaderComponent } from './components/block/block.component';
import { CepInputComponent } from './components/cep-input/cep-input.component';
import { ComboComponent } from './components/combo/combo.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CouponsComponent } from './components/coupons/coupons.component';
import { CustomerLoginComponent } from './components/customer-login/customer-login.component';
import { DataGridComponent } from './components/datagrid/datagrid.component';
import { EntitySelectionComponent } from './components/entity-selection/entity-selection.component';
import { ForgotComponent } from './components/forgot/forgot.component';
import { GroupedComponent } from './components/grouped/grouped.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { InputSectionComponent } from './components/input-section/input-section.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalComponent } from './components/modal/modal.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { OrderComponent } from './components/orders/order.component';
import { OrdersComponent } from './components/orders/orders.component';

import { PersonalDataComponent } from './components/personal-data/personal.data.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { VerticalGalleryComponent } from './components/vertical-gallery/vertical-gallery.component';
import { WhatsappComponent } from './components/whatsapp/whatsapp.component';
import { CustomerPageComponent } from './pages/customer/customer.page.component';
import { AllCartsPageComponent } from './pages/home/all-carts/all.carts.component';
import { AllCouponsPageComponent } from './pages/home/all-coupons/all.coupons.component';
import { AllCustomersPageComponent } from './pages/home/all-customers/all.customers.component';
import { AllProductsPageComponent } from './pages/home/all-products/all.products.component';
import { AllRequestsPageComponent } from './pages/home/all-requests/all.requests.component';
import { AllWhatsappPageComponent } from './pages/home/all-whatsapp/all.whatsapp.component';
import { HomePageComponent } from './pages/home/home.page.component';
import { ReportsPageComponent } from './pages/home/reports/reports.component';
import { UserAddressPageComponent } from './pages/home/user-address/user.address.component';
import { UserConfigsPageComponent } from './pages/home/user-configs/user.configs.component';
import { UserRequestsPageComponent } from './pages/home/user-requests/user.requests.component';
import { ProductOrderComponent } from './pages/product-filter/product-order.component';
registerLocaleData(localePT);

@NgModule({
  declarations: [
    AppComponent,
    CustomerPageComponent,
    CustomerLoginComponent,
    SignUpComponent,
    CepInputComponent,
    LoadingComponent,
    DataGridComponent,
    EntitySelectionComponent,
    BlockLoaderComponent,
    AddressComponent,
    PersonalDataComponent,
    ComboComponent,
    ImageGalleryComponent,
    ModalComponent,
    InputSectionComponent,
    ProductOrderComponent,
    VerticalGalleryComponent,
    OrdersComponent,
    OrderComponent,
    OrderStatusComponent,
    OnlyNumberDirective,

    // MENUS
    HomePageComponent,
    UserRequestsPageComponent,
    UserConfigsPageComponent,
    UserAddressPageComponent,
    AllCartsPageComponent,
    AllCustomersPageComponent,
    AllProductsPageComponent,
    AllRequestsPageComponent,
    AllCouponsPageComponent,
    AllWhatsappPageComponent,
    CouponsComponent,
    ReportsPageComponent,
    WhatsappComponent,
    ForgotComponent,
    GroupedComponent,
    ConfirmDialogComponent,
  ],
  imports: [
    AgGridModule,
    BrowserModule,
    BrowserAnimationsModule,
    ButtonModule,
    CarouselModule,
    ConfirmDialogModule,
    DropdownModule,
    EditorModule,
    FormsModule,
    HttpClientModule,
    InputTextModule,
    NgxMaskDirective,
    NgxMaskPipe,
    PaginatorModule,
    SwiperModule,
    TableModule,
    TagModule,
    ToastModule,
    TooltipModule,
  ],
  providers: [
    ConfirmationService,
    provideNgxMask(),
    MessageService,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
