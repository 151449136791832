export default class OrderShipping {
  tokenOffer?: string;
  offer?: number;
  cnpj?: string;
  logo?: string;
  name?: string;
  service?: string;
  serviceDescription?: string;
  deliveryTime?: number;
  estimatedDelivery?: Date;
  validity?: Date;
  costFreight?: number;
  priceFreight?: number;
  shippingCarrierNormalized?: string;
  shippingCarrierTypeNormalized?: string;
  dateStorePickup?: Date;
  optionFreight?: string;
  daysStorePickup?: number;
}
